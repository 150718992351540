import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder'
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react'
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './pages/catalog/EntityPage';
import { searchPage } from './pages/search/SearchPage';
import { Layout } from './layout';
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { oktaAuthApiRef } from '@backstage/core-plugin-api';
import { HomepageCompositionRoot, VisitListener } from '@backstage/plugin-home';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { w3fTheme } from './style/theme';
import { HomePage } from './pages/home/HomePage';
import { ROUTES } from './constants/routes';
import { SelectFieldFromApiExtension } from '@roadiehq/plugin-scaffolder-frontend-module-http-request-field'
import type { IdentityApi } from '@backstage/core-plugin-api';
import { discoveryApiRef, useApi } from '@backstage/core-plugin-api';
import { setTokenCookie } from './cookieAuth';


const app = createApp({
  components: {
    SignInPage: props => {
      const discoveryApi = useApi(discoveryApiRef);
      return (
        <SignInPage
          {...props}
          align="center"
          provider={{
            id: 'okta-auth-provider',
            title: 'Okta',
            message: 'Sign in using Okta',
            apiRef: oktaAuthApiRef,
          }}
          onSignInSuccess={async (identityApi: IdentityApi) => {
            await setTokenCookie(
              await discoveryApi.getBaseUrl('cookie'),
              identityApi,
            );
            props.onSignInSuccess(identityApi);
          }}
        />
      )
    },
  },
  apis,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  themes: [
    {
      id: 'theme',
      title: 'Dark Theme',
      variant: 'dark',
      Provider: ({ children }) => (
        <ThemeProvider theme={w3fTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
  ],
});

const routes = (
  <FlatRoutes>
    <Navigate key="/" to={ROUTES.HOME} />
    <Route path={ROUTES.HOME} element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path={ROUTES.CATALOG} element={<CatalogIndexPage />} />
    <Route
      path={`${ROUTES.CATALOG}/:namespace/:kind/:name`}
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path={ROUTES.APIS} element={<ApiExplorerPage />} />
    <Route path={ROUTES.DOCS} element={<TechDocsIndexPage />} />
    <Route
      path={`${ROUTES.DOCS}/:namespace/:kind/:name/*`}
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path={ROUTES.CREATE} element={<ScaffolderPage />}>
      <ScaffolderFieldExtensions>
        <SelectFieldFromApiExtension />
      </ScaffolderFieldExtensions>
    </Route>
    {/*<Route path={ROUTES.CREATE} element={<ScaffolderPage />} />*/}
    <Route path={ROUTES.SETTINGS} element={<UserSettingsPage />} />
    <Route path={ROUTES.CATALOG_GRAPH} element={<CatalogGraphPage />} />
    <Route
      path={ROUTES.TECH_RADAR}
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route
      path={ROUTES.CATALOG_IMPORT}
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path={ROUTES.SEARCH} element={<SearchPage />}>
      {searchPage}
    </Route>
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />

    <AppRouter>
      <VisitListener>
        <Layout>{routes}</Layout>
      </VisitListener>
    </AppRouter>
  </>,
);
