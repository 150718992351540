export const ROUTES = {
  HOME: '/home',
  SEARCH: '/search',
  CATALOG: '/catalog',
  APIS: '/apis',
  DOCS: '/docs',
  CREATE: '/create',
  TECH_RADAR: '/tech-radar',
  SETTINGS: '/settings',
  CATALOG_GRAPH: '/catalog-graph',
  CATALOG_IMPORT: '/catalog-import',
};
