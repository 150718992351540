import { sidebarConfig } from '@backstage/core-components';
import { BackstageTheme } from '@backstage/theme';
import { makeStyles } from '@material-ui/core';

export const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: '16px',
  },
});

const customSearchBar = (theme: BackstageTheme) => ({
  searchBar: {
    display: 'flex',
    maxWidth: '60vw',
    padding: '0px',
    borderRadius: '50px',
    margin: 'auto',
    backgroundColor: theme.palette.secondary.main,
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
});

export const useSearchPageStyles = makeStyles((theme: BackstageTheme) => ({
  ...customSearchBar(theme),
  bar: {
    padding: theme.spacing(1, 0),
  },
  filters: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  filter: {
    '& + &': {
      marginTop: theme.spacing(2.5),
    },
  },
}));

export const useHomePageStyles = makeStyles((theme: BackstageTheme) => ({
  ...customSearchBar(theme),
}));

const transition = '300ms ease-out';

export const sidebarStylesOverrides = (theme: BackstageTheme) => ({
  BackstageSidebar: {
    drawer: {
      backgroundColor: 'transparent',
    },
  },
  BackstageSidebarItem: {
    root: {
      margin: '10px',
      textDecoration: 'none',
      alignItems: 'center',
      opacity: '80%',
      color: theme.palette.primary.main,
      paddingTop: '10px',
      paddingBottom: '10px',
      '&:hover': {
        opacity: '100%',
        backgroundColor: 'transparent!important',
        '& > div': {
          transition,
          backgroundColor: theme.palette.secondary.main,
        },
        '& > span': {
          transition,
          fontWeight: 700,
        },
      },
    },
    selected: {
      borderLeft: 'none!important',
      color: `${theme.palette.primary.main}!important`,
      opacity: '100%',

      '& > div': {
        transition,
        backgroundColor: theme.palette.secondary.main,
        marginLeft: '0px!important',
      },
      '& > span': {
        transition,
        fontWeight: 700,
      },
    },
    iconContainer: {
      width: '44px',
      height: '44px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.primary.main,
      borderRadius: '3px',
      marginRight: '8px',
    },
    label: {
      fontSize: '18px',
      fontWeight: 400,
    },
  },
  MuiBottomNavigation: {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  MuiBottomNavigationAction: {
    root: {
      padding: '0px!important',
      height: '44px',
      color: theme.palette.primary.main,
      borderRadius: '3px',
    },
    selected: {
      borderTop: 'none!important',
      backgroundColor: theme.palette.secondary.main,
      color: `${theme.palette.primary.main}!important`,
      opacity: '100%',
      transition,
    },
  },
});
