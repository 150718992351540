import searchIcon from '../../assets/icons/search.svg';
import React from 'react';

export const SearchIcon = (props: any) => (
  <img
    src={searchIcon}
    alt="Search"
    {...props}
    style={{ transform: 'scaleX(-1)' }}
  />
);
