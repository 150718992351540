import React, { PropsWithChildren } from 'react';
import {
  Sidebar,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  Link,
} from '@backstage/core-components';

import { ROUTES } from '../constants/routes';

import LogoFull from '../components/icons/LogoFull';
import LogoIcon from '../components/icons/LogoIcon';
import { HomeIcon } from '../components/icons/HomeIcon';
import { CatalogIcon } from '../components/icons/CatalogIcon';
import { ApisIcon } from '../components/icons/ApisIcon';
import { DocsIcon } from '../components/icons/DocsIcon';
import { CreateIcon } from '../components/icons/CreateIcon';
import { SettingsIcon } from '../components/icons/SettingsIcon';
import { RadarIcon } from '../components/icons/RadarIcon';
import { SearchIcon } from '../components/icons/SearchIcon';

import { useSidebarLogoStyles } from '../style/custom';

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();

  return (
    <div className={classes.root}>
      <Link
        to={ROUTES.HOME}
        underline="none"
        className={classes.link}
        aria-label="Home"
      >
        <LogoFull />
      </Link>
    </div>
  );
};

export const Layout = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to={ROUTES.SEARCH}>
        <SidebarItem icon={SearchIcon} to={ROUTES.SEARCH} text="Search" />
      </SidebarGroup>
      <SidebarGroup label="" icon={<LogoIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon} to={ROUTES.HOME} text="Home" />
        <SidebarItem icon={CatalogIcon} to={ROUTES.CATALOG} text="Catalog" />
        <SidebarItem icon={ApisIcon} to={ROUTES.APIS} text="APIs" />
        <SidebarItem icon={DocsIcon} to={ROUTES.DOCS} text="Docs" />
        <SidebarItem icon={CreateIcon} to={ROUTES.CREATE} text="Create" />
        {/* End global nav */}
        <SidebarItem
          icon={RadarIcon}
          to={ROUTES.TECH_RADAR}
          text="Tech Radar"
        />
      </SidebarGroup>
      <SidebarSpace />
      <SidebarGroup
        label="Settings"
        icon={<SettingsIcon />}
        to={ROUTES.SETTINGS}
      >
        <SidebarItem icon={SettingsIcon} to={ROUTES.SETTINGS} text="Settings" />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
