import { BackstageOverrides } from '@backstage/core-components';
import {
  createTheme,
  createBaseThemeOptions,
  palettes,
  BackstageTheme,
} from '@backstage/theme';
import '@fontsource/space-mono';
import '@fontsource/space-mono/700.css';
import '@fontsource/space-mono/400-italic.css';
import '@fontsource/space-mono/700-italic.css';
import { sidebarStylesOverrides } from './custom';

const baseTheme = createTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      primary: {
        main: '#FAF8EA',
      },
      secondary: {
        main: '#1A1D20',
        dark: '#0B0B0B',
      },
      error: {
        main: '#F47459',
      },
      success: {
        main: '#59F474',
      },
      info: {
        main: '#08209D',
      },
    },
    fontFamily: 'Space Mono, monospace',
  }),
});

const borderRadius = '12px!important';

const createCustomThemeOverrides = (
  theme: BackstageTheme,
): BackstageOverrides => {
  return {
    // GLOBAL
    MuiCssBaseline: {
      '@global': {
        body: {
          background:
            'radial-gradient(95.43% 95.43% at 52.08% 100%, #0D0D0D 0%, #292E34 100%)',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
        },
        // SCROLLBAR
        '::-webkit-scrollbar': {
          width: '8px',
        },
        '::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '::-webkit-scrollbar-thumb': {
          background: theme.palette.secondary.main,
          borderRadius: '10px',
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: theme.palette.secondary.main,
        },
      },
    },

    // TYPOGRAPHY
    MuiTypography: {
      h5: {
        fontWeight: 400,
        fontSize: '20px',
      },
      body1: {
        fontWeight: 400,
        fontSize: '12px',
      },
    },

    // ICONS
    MuiListItemIcon: {
      root: {
        color: theme.palette.primary.main,
      },
    },

    // BUTTONS
    MuiButton: {
      text: {
        padding: '10px 20px',
        borderRadius: '25px',
        '&:hover': {
          backgroundColor: 'rgba(250, 248, 234, 0.2)!important',
        },
      },
      label: {
        textTransform: 'capitalize',
      },
      containedPrimary: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
        borderRadius: '25px',
        textTransform: 'capitalize',
        padding: '10px 20px',
        boxShadow: 'none!important',
        '&:hover': {
          backgroundColor: 'rgba(250, 248, 234, 0.2)!important',
        },
      },
      textPrimary: {
        color: theme.palette.primary.main,
        borderRadius: '25px',
        textTransform: 'capitalize',
        padding: '10px 20px',
        '&:hover': {
          backgroundColor: 'rgba(250, 248, 234, 0.2)!important',
        },
      },
    },

    // CHIP
    MuiChip: {
      root: {
        backgroundColor: '#2F4158',
      },
    },

    // CARDS
    BackstageEmptyState: {
      root: {
        backgroundColor: 'transparent',
        borderRadius,
      },
    },

    BackstageInfoCard: {
      headerTitle: {
        fontWeight: 400,
        fontSize: '20px',
      },
    },

    BackstageItemCardHeader: {
      root: {
        backgroundImage: `linear-gradient(to right, ${theme.palette.secondary.dark}, #007D49)!important`, // to replace with desired image/color
      },
    },

    MuiPaper: {
      rounded: {
        backgroundColor: `${theme.palette.secondary.dark}!important`,
        borderRadius,
        color: theme.palette.primary.main,
      },
      elevation16: {
        boxShadow: 'none!important',
      },
    },

    MuiListItem: {
      gutters: {
        '&:hover': {
          backgroundColor: 'rgba(250, 248, 234, 0.2)!important',
        },
      },

      secondaryAction: {
        '&:hover': {
          backgroundColor: 'transparent!important',
        },
      },

      selected: {
        backgroundColor: 'rgba(250, 248, 234, 0.2)!important',
      },
    },

    MuiCard: {
      root: {
        backgroundColor: theme.palette.secondary.dark,
        borderRadius,
      },
    },

    MuiAccordion: {
      root: {
        backgroundColor: `${theme.palette.secondary.dark}!important`,
        borderRadius,
      },
    },

    // SIDEBAR
    ...sidebarStylesOverrides(theme),

    // HEADERS
    BackstageHeader: {
      header: {
        display: 'none',
      },
    },

    BackstageHeaderTabs: {
      tabsWrapper: {
        backgroundColor: theme.palette.secondary.dark,
        borderRadius,
        margin: '24px',
      },
    },

    // TABLES
    MuiTable: {
      root: {
        backgroundColor: theme.palette.secondary.dark,
        borderRadius,
      },
    },

    BackstageTableHeader: {
      header: {
        backgroundColor: 'transparent!important',
      },
    },

    MuiTableRow: {
      root: {
        backgroundColor: 'transparent!important',
      },
      head: {
        backgroundColor: 'transparent!important',
      },
    },
  };
};

export const w3fTheme: BackstageTheme = {
  ...baseTheme,
  overrides: {
    ...baseTheme.overrides,
    ...createCustomThemeOverrides(baseTheme),
  },
};
